import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

const TitleAbout = (): JSX.Element => (
  <Grid container>
    <Grid xs={12}>
      <Typography variant="h1">
        About James
        <br />
        Kirkpatrick
        <br />
        Group Ltd
      </Typography>
    </Grid>
  </Grid>
);

export default TitleAbout;
