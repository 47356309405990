const currency = (value: string | number, currencyCode: string = 'NZD', roundToWhole: Boolean = false) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: roundToWhole ? 0 : 2, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(Number(value));
};

export default currency;
