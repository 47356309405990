import React, {forwardRef} from 'react';

import {ButtonColorsTypesDefault, ButtonProps, ButtonSizesTypesDefault, ButtonVariantsTypesDefault, Ref} from './Button.types';
import ButtonStyled from './ButtonStyled';

const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      color = ButtonColorsTypesDefault,
      variant = ButtonVariantsTypesDefault,
      size = ButtonSizesTypesDefault,
      circular = false,
      icononly = false,
      children,
      ...rest
    }: ButtonProps,
    ref
  ): JSX.Element => (
    // @ts-ignore
    <ButtonStyled {...rest} ref={ref} color={color} variant={variant} size={size} circular={circular} icononly={icononly}>
      {children}
    </ButtonStyled>
  )
);

export default Button;
