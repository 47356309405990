import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

const styleMobile = {
  display: {xs: 'none', lg: 'block'},
};

const TitleHomeDescription = (): JSX.Element => (
  <Grid container>
    <Grid xs={12}>
      <Typography sx={{pb: 1}}>
        As a family owned business with over six decades experience in the property market we have a rich and diverse history. But what is even more important
        now is our future and the future of our planet.
      </Typography>
      <Typography sx={styleMobile}>
        Just as we take a long-term view of our investments and our buildings, we also take a long-term view of our impact on our environment. We are actively
        working towards reducing our carbon footprint and creating healthy workplaces for our people, our communities and our clients.
      </Typography>
    </Grid>
  </Grid>
);

export default TitleHomeDescription;
