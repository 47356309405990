'use client';

import React, {useMemo, useState} from 'react';

import {useRouter} from 'next/router';

import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import round from 'lodash/round';
import trimStart from 'lodash/trimStart';

import {PropertyGalleryInterface} from '@/interfaces/properties';
import {Button} from '@/components/Button';
import {Divider} from '@/components/Divider';
import {Modal} from '@/components/Modal';
import PropertyGallery from '@/components/Property/PropertyGallery';
import PropertyGalleryImages from '@/components/Property/PropertyGalleryImages';
import {SocialShare} from '@/components/SocialShare';
import {Title} from '@/components/Title';
import currency from '@/helpers/currency';

const maxWidthDefault = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

const styleContent = (maxWidth: number) => ({
  maxWidth: `${maxWidth}px`,
  width: '100%',
});

const styleGalleryWrapper = {
  '@media print': {
    maxWidth: '50%',
    margin: '0 auto',
  },
};

const styleGalleryWrapperMobile = {
  position: 'relative',
  right: '-10px',
};

const styleButtonWrapper = {
  '@media print': {
    display: 'none',
  },
};

export interface PropertyDetailsProps {
  title: string;
  description: string;
  tenancy: string;
  type: string;
  area: string;
  rentPerWeek: string;
  rentPerYear: string;
  autoplay?: boolean;
  interval?: number;
  showPagination?: boolean;
  showPaginationArrows?: boolean;
  showTitleArrows?: boolean;
  subtitle?: string;
  address?: string;
  property?: PropertyGalleryInterface;
}

const PropertyDetails = ({
  address = '',
  area = '',
  autoplay = false,
  description = '',
  interval = 3000,
  rentPerWeek = '',
  rentPerYear = '',
  showPagination = false,
  showPaginationArrows = false,
  showTitleArrows = false,
  subtitle = '',
  tenancy = '',
  title = '',
  type = '',
  property = {} as PropertyGalleryInterface,
}: PropertyDetailsProps): JSX.Element => {
  const router = useRouter();
  const {asPath} = router;
  const shareUrl = `${process.env.NEXT_PUBLIC_FRONTEND_URL}${trimStart(asPath, '/')}`;

  const [activeStep, setActiveStep] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const imagesMobile = PropertyGalleryImages(property, 1, true, '1 / 1');
  const imagesDesktop = PropertyGalleryImages(property, 1, true);
  const itemCount = (imagesMobile && imagesMobile.length) ?? 0;
  const pageCount = Math.ceil(itemCount / 1) ?? 0;

  const disabledButtonPrev = useMemo(() => activeStep === 0, [activeStep]);
  const disabledButtonNext = useMemo(() => activeStep >= pageCount - 1, [activeStep, pageCount]);
  const showPaginationElements = useMemo(() => showPagination && pageCount > 1, [showPagination, pageCount]);

  return (
    <Grid container justifyContent="center" m={2}>
      <Grid container sx={styleContent(maxWidthDefault)}>
        <Grid xs={12}>
          <Title
            title={title}
            subtitle={subtitle}
            showPaginationElements={showPaginationElements}
            showTitleArrows={showTitleArrows}
            handleBack={handleBack}
            handleNext={handleNext}
            disabledButtonPrev={disabledButtonPrev}
            disabledButtonNext={disabledButtonNext}
          />
        </Grid>

        <Grid container xs={12} lg={4} pr={{xs: 0, lg: 8}} alignContent="flex-start">
          <Grid container xs={12} spacing={2}>
            {address && (
              <Grid xs={12}>
                <Typography variant="h3">{address}</Typography>
              </Grid>
            )}

            {description && (
              <Grid xs={12}>
                <Typography>{description}</Typography>
              </Grid>
            )}

            {itemCount > 0 && (
              <Hidden lgUp>
                <Grid xs={12} lg={8} mb={1} sx={styleGalleryWrapper}>
                  <PropertyGallery
                    activeStep={activeStep}
                    autoplay={autoplay}
                    images={imagesMobile}
                    interval={interval}
                    itemCount={itemCount}
                    pageCount={pageCount}
                    showPagination={showPagination}
                    showPaginationArrows={showPaginationArrows}
                  />
                </Grid>
              </Hidden>
            )}

            <Grid xs={12}>
              <Divider />
            </Grid>

            {tenancy && (
              <Grid container xs={12}>
                <Grid xs={6}>
                  <Typography>Tenancy:</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography fontWeight={600}>{tenancy}</Typography>
                </Grid>
              </Grid>
            )}

            {type && (
              <Grid container xs={12}>
                <Grid xs={6}>
                  <Typography>Type:</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography fontWeight={600}>{type}</Typography>
                </Grid>
              </Grid>
            )}

            {area && (
              <Grid container xs={12}>
                <Grid xs={6}>
                  <Typography>Office (sqm):</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography fontWeight={600}>{round(Number(area))}</Typography>
                </Grid>
              </Grid>
            )}

            {area && (
              <Grid container xs={12}>
                <Grid xs={6}>
                  <Typography>Area (sqm):</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography fontWeight={600}>{round(Number(area))}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid xs={12}>
              <Divider />
            </Grid>

            {(rentPerWeek || rentPerYear) && (
              <Grid container xs={12}>
                <Grid xs={12}>
                  <Typography variant="h3">Net Rent</Typography>
                </Grid>

                {rentPerWeek && (
                  <Grid container xs={12}>
                    <Grid xs={6}>
                      <Typography>Per week:</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography fontWeight={600}>{currency(rentPerWeek)}</Typography>
                    </Grid>
                  </Grid>
                )}

                {rentPerYear && (
                  <Grid container xs={12}>
                    <Grid xs={6}>
                      <Typography>Per year:</Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography fontWeight={600}>{currency(rentPerYear)}</Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid xs={12}>
                  <Divider />
                </Grid>

                <Grid xs={12}>
                  <Typography>plus GST and OPEX</Typography>
                </Grid>

                <Grid container xs={12} pt={2} sx={styleButtonWrapper}>
                  <Grid>
                    <Button variant="outlined" color="primary" size="large" circular disableElevation onClick={() => window.print()}>
                      Print
                    </Button>
                  </Grid>
                  <Grid>
                    <Button variant="outlined" color="primary" size="large" circular disableElevation onClick={handleOpen}>
                      Share
                    </Button>
                    <Modal open={open} onClose={handleClose}>
                      <SocialShare shareUrl={shareUrl} title="testing" />
                    </Modal>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {itemCount <= 0 && (
          <Grid xs={12} lg={8} pt={{xs: 0, lg: 2}}>
            <Typography>NO RESULTS FOUND</Typography>
            <Typography>Please try another search term or category using the above search box.</Typography>
          </Grid>
        )}

        {itemCount > 0 && (
          <Hidden lgDown>
            <Grid xs={12} lg={8} sx={styleGalleryWrapperMobile}>
              <PropertyGallery
                activeStep={activeStep}
                autoplay={autoplay}
                images={imagesDesktop}
                interval={interval}
                itemCount={itemCount}
                pageCount={pageCount}
                showPagination={showPagination}
                showPaginationArrows={showPaginationArrows}
              />
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Grid>
  );
};

export default PropertyDetails;
