import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

const TitleAboutDescription = (): JSX.Element => (
  <Grid container>
    <Grid xs={12}>
      <Typography sx={{pb: 1}}>
        We are an Auckland-based, family owned and operated, property investment company. We have been involved in Auckland’s property market for 60 years and
        have been through many cycles of the property industry. We continue to take a long-term investment position.
      </Typography>
    </Grid>
  </Grid>
);

export default TitleAboutDescription;
