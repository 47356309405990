'use client';

import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Hidden from '@mui/material/Hidden';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Button} from '@/components/Button';
import {Divider} from '@/components/Divider';
import {Search} from '@/components/Search';

const styleTitleWrapper = {
  marginTop: [1, 1, 1, 6, 6],
  marginBottom: [1, 1, 1, 3, 3],

  '@media print': {
    marginTop: 1,
  },
};
const styleButton = {
  borderColor: 'text.primary',
  borderWidth: 3,
  color: 'text.primary',
  height: 52,
  width: 52,

  '&:disabled': {
    borderWidth: 3,
    stroke: 'rgba(0, 0, 0, 0.26)',
    strokeWidth: 2,

    svg: {
      stroke: 'rgba(0, 0, 0, 0.1)',
      fill: 'rgba(0, 0, 0, 0.1)',
    },
  },

  '&:hover': {
    background: 'rgba(0, 0, 0, 0.1)',
    borderColor: 'text.primary',
    borderWidth: 3,
  },
};

const styleIcon = {
  fontSize: '1.3rem !important',
  stroke: '#2C2D30',
  fill: '#2C2D30',
  strokeWidth: 2,
};

const styleSearch = {
  minWidth: 500,
  marginTop: 4,
};

const stylePaginationWrapper = {
  '@media print': {
    display: 'none',
  },
};

export interface TitleProps {
  title?: string;
  subtitle?: string;
  showPaginationElements?: boolean;
  showTitleArrows?: boolean;
  showSearch?: boolean;
  handleBack?: () => void;
  handleNext?: () => void;
  disabledButtonPrev?: boolean;
  disabledButtonNext?: boolean;
  sx?: object;
}

const Title = ({
  title = '',
  subtitle = '',
  showPaginationElements = false,
  showTitleArrows = false,
  showSearch = false,
  handleBack,
  handleNext,
  disabledButtonPrev,
  disabledButtonNext,
  sx,
}: TitleProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="end" sx={{...styleTitleWrapper, ...sx}}>
      <Grid>
        <Grid container>
          <Grid xs={12}>{title && <Typography variant="h2">{title}</Typography>}</Grid>
          {subtitle && (
            <Grid xs={12}>
              <Typography variant="h3" sx={{textTransform: 'uppercase'}}>
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {showPaginationElements && showTitleArrows && (
        <Hidden lgDown>
          <Grid sx={stylePaginationWrapper}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid>
                <Button variant="outlined" circular icononly aria-label="previous" onClick={handleBack} disabled={disabledButtonPrev} sx={styleButton}>
                  {theme.direction === 'rtl' ? <ArrowForwardIcon sx={styleIcon} /> : <ArrowBackIcon sx={styleIcon} />}
                </Button>
              </Grid>
              <Grid>
                <Button variant="outlined" circular icononly aria-label="next" onClick={handleNext} disabled={disabledButtonNext} sx={styleButton}>
                  {theme.direction === 'rtl' ? <ArrowBackIcon sx={styleIcon} /> : <ArrowForwardIcon sx={styleIcon} />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      )}

      {showSearch && (
        <Hidden lgDown>
          <Grid p={0}>
            <Grid display="flex" justifyContent="flex-end" alignItems="flex-end" p={0}>
              <Search label="Search by location or keyword" sx={styleSearch} />
            </Grid>
          </Grid>
        </Hidden>
      )}

      <Grid xs={12} mt={{xs: 0, lg: 2}}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default Title;
