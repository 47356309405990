import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Search} from '@/components/Search';

const styleSearchWrapper = {
  width: '100%',
  marginTop: 4,
};

const styleSearch = {
  maxWidth: 500,
  width: '100%',
  marginTop: 4,
};

const TitleHome = (): JSX.Element => (
  <Grid container>
    <Grid xs={12}>
      <Typography variant="h1">
        Sustainability.
        <br />
        Our most important
        <br />
        long-term investment
      </Typography>
    </Grid>
    <Grid xs={12} sx={styleSearchWrapper}>
      <Search label="Search by location or keyword" sx={styleSearch} />
    </Grid>
  </Grid>
);

export default TitleHome;
