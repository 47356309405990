'use client';

import React, {useEffect, useMemo, useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import {useTheme} from '@mui/material/styles';

import {Button} from '@/components/Button';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const stylePaginationWrapper = {
  '@media print': {
    display: 'none',
  },
};

export interface PropertyGalleryProps {
  images: Array<JSX.Element>;
  autoplay?: boolean;
  interval?: number;
  itemCount?: number;
  pageCount?: number;
  showPagination?: boolean;
  showPaginationArrows?: boolean;
  activeStep?: number;
}

const PropertyGallery = ({
  autoplay = false,
  images = [],
  interval = 3000,
  itemCount = 0,
  pageCount = 0,
  showPagination = false,
  showPaginationArrows = false,
  activeStep = 0,
}: PropertyGalleryProps): JSX.Element => {
  const theme = useTheme();
  const [activeGalleryStep, setActiveStep] = useState(activeStep);

  useEffect(() => {
    setActiveStep(activeStep);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const disabledButtonPrev = useMemo(() => activeGalleryStep === 0, [activeGalleryStep]);
  const disabledButtonNext = useMemo(() => activeGalleryStep >= pageCount - 1, [activeGalleryStep, pageCount]);
  const showPaginationElements = useMemo(() => showPagination && pageCount > 1, [showPagination, pageCount]);

  return (
    <Box>
      {itemCount > 0 && (
        <AutoPlaySwipeableViews
          autoplay={autoplay}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          enableMouseEvents
          index={activeGalleryStep}
          interval={interval}
          onChangeIndex={handleStepChange}>
          {images}
        </AutoPlaySwipeableViews>
      )}

      {showPaginationElements && (
        <MobileStepper
          steps={pageCount}
          position="static"
          activeStep={activeGalleryStep}
          variant="dots"
          nextButton={
            showPaginationArrows ? (
              <Button size="small" onClick={handleNext} disabled={disabledButtonNext}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            ) : (
              <>&nbsp;</>
            )
          }
          backButton={
            showPaginationArrows ? (
              <Button size="small" onClick={handleBack} disabled={disabledButtonPrev}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            ) : (
              <>&nbsp;</>
            )
          }
          sx={stylePaginationWrapper}
        />
      )}
    </Box>
  );
};

export default PropertyGallery;
