/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import type {SxProps} from '@mui/material';
import Box from '@mui/material/Box';

const styleBackgroundVideoWrapper = (aspectRatio = '1 / 1', maxHeight = 'inherit', background = false) => ({
  aspectRatio,
  minHeight: 'inherit',
  minWidth: '100%',
  position: background ? 'absolute' : 'relative',
  width: '100%',
  maxHeight,
  top: 0,
});

const styleBackgroundVideo = (background = false) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: background ? -10 : 'auto',
});

const styleOverlay = (background = false) => ({
  background: 'transparent linear-gradient(180deg, #677F88 0%, #7E929AD8 30%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
  bottom: 0,
  color: '#fff',
  left: 0,
  mixBlendMode: 'multiply',
  overflow: 'hidden',
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  top: 0,
  transform: 'matrix(-1, 0, 0, -1, 0, 0)',
  width: '100%',
  zIndex: background ? -5 : 'auto',
});

export interface VideoWrapperProps {
  video?: string;
  title?: string;
  aspectRatio?: string;
  maxHeight?: string;
  background?: boolean;
  overlay?: boolean;
  sx?: SxProps;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
}

const VideoRatio = ({
  video = '',
  title = '',
  aspectRatio = '1 / 1',
  maxHeight = 'inherit',
  background = false,
  overlay = false,
  sx = {},
  ...rest
}: VideoWrapperProps): JSX.Element => {
  const videoElement = (
    <video
      src={video}
      alt={title}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
      // @ts-ignore
      style={{...styleBackgroundVideo(background), ...sx}}
      {...rest}
    />
  );

  return (
    <Box sx={styleBackgroundVideoWrapper(aspectRatio, maxHeight, background)}>
      {overlay && <Box sx={{...styleOverlay(background)}} />}
      {videoElement}
    </Box>
  );
};

export default VideoRatio;
