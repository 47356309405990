/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from 'react';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from 'react-share';

import {event} from 'nextjs-google-analytics';

import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import {Divider} from '@/components/Divider';

export interface SocialShareInterface {
  shareUrl: string;
  title: string;
}

const SocialShare = ({shareUrl, title}: SocialShareInterface): JSX.Element => {
  const options = {category: 'Contact'};
  const handleFacebook = useCallback(() => event('Clicked to Share to Facebook', options), []);
  const handleLinkedIn = useCallback(() => event('Clicked to Share to LinkedIn', options), []);
  const handleTwitter = useCallback(() => event('Clicked to Share to Twitter', options), []);
  const handleWhatsApp = useCallback(() => event('Clicked to Share to WhatsApp', options), []);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid xs={12}>
        <Typography variant="h3" align="center">
          Share This Property
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid>
        {/* @ts-ignore */}
        <EmailShareButton url={shareUrl} quote={title}>
          <IconButton color="inherit" size="large" edge="end" aria-label="Share on Facebook">
            <EmailIcon />
          </IconButton>
        </EmailShareButton>
      </Grid>
      <Grid>
        <FacebookShareButton url={shareUrl} quote={title} onClick={handleFacebook}>
          <IconButton color="inherit" size="large" edge="end" aria-label="Share on Facebook">
            <FacebookIcon />
          </IconButton>
        </FacebookShareButton>
      </Grid>
      <Grid>
        {/* @ts-ignore */}
        <TwitterShareButton url={shareUrl} quote={title} onClick={handleTwitter}>
          <IconButton color="inherit" size="large" edge="end" aria-label="Share on Facebook">
            <TwitterIcon />
          </IconButton>
        </TwitterShareButton>
      </Grid>
      <Grid>
        {/* @ts-ignore */}
        <LinkedinShareButton url={shareUrl} quote={title} onClick={handleLinkedIn}>
          <IconButton color="inherit" size="large" edge="end" aria-label="Share on Facebook">
            <LinkedInIcon />
          </IconButton>
        </LinkedinShareButton>
      </Grid>
      <Grid>
        {/* @ts-ignore */}
        <WhatsappShareButton url={shareUrl} quote={title} onClick={handleWhatsApp}>
          <IconButton color="inherit" size="large" edge="end" aria-label="Share on Facebook">
            <WhatsAppIcon />
          </IconButton>
        </WhatsappShareButton>
      </Grid>
    </Grid>
  );
};

export default SocialShare;
