import React, {forwardRef, useCallback, useEffect} from 'react';

import {useRouter} from 'next/router';

import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

import useSWR from 'swr';

import {Button} from '@/components/Button';

import Filter from './Filter';
import {Ref, TextFieldProps} from './Search.types';

const fetcher = (url: string) => fetch(url).then(res => res.json());

const styleInput = {
  alignItems: 'center',
  borderRadius: '3rem',
  display: 'flex',
  position: 'relative',
  width: 400,
  padding: '3px 120px 3px 10px',
  border: '1px solid rgba(0, 0, 0, 0.4)',
  boxShadow: 'none',
  margin: '0  !important',
};

const styleButtonIcon = {
  p: '10px',
};

const styleButtonSubmit = {
  position: 'absolute',
  top: -1,
  right: -1,
  height: '52px',
  fontSize: '1rem',
  boxShadow: 'none',
};

// const fetcher = (url: string) => fetch(url).then(res => res.json());

const Search = forwardRef<Ref, TextFieldProps>(({sx}: TextFieldProps): JSX.Element => {
  const router = useRouter();
  const {
    category, // Used when a search is done, this is put in the url as a parameter e.g. ?category=warehouses
    q, // This is the search query e.g. ?q=westney
    categoryId, // This is the category id when on a category page e.g. /warehouses
  } = router.query;
  const {data: categories, error: categoryError} = useSWR('/api/categories', fetcher);
  const [type, setType] = React.useState<string>('all');
  const [qSearch, setQSearch] = React.useState<string>('');
  const [showFilters, setShowFilters] = React.useState<boolean>(false);

  useEffect(() => {
    setQSearch(q as string);
    setType((category as string) ?? categoryId ?? 'all');
  }, [q, category, categoryId]);

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      const {currentTarget: form} = event;
      const formData = new FormData(form);
      const query = formData.get('query');
      router.push(`/search?category=${type}&q=${query ?? ''}`);
    },
    [type, router]
  );

  const onSearchInput = useCallback((event: any) => {
    setQSearch(event.target.value);
  }, []);

  const onChangeType = useCallback((event: any) => {
    setType(event.target.dataset.value);
    setShowFilters(false);
  }, []);

  const onShowFilters = useCallback(() => {
    setShowFilters(prevState => !prevState);
  }, []);

  if (categoryError) return <p>There is an error.</p>;
  return (
    // @ts-ignore
    <Paper onSubmit={onSubmit} component="form" sx={{...styleInput, ...sx}}>
      <InputBase
        value={qSearch}
        onInput={onSearchInput}
        name="query"
        sx={{ml: 1, flex: 1}}
        placeholder="Search by location or keyword"
        inputProps={{'aria-label': 'search google maps'}}
      />

      <IconButton onClick={onShowFilters} color="primary" sx={styleButtonIcon} aria-label="directions">
        <TuneIcon />
      </IconButton>

      <Button type="submit" variant="contained" color="secondary" circular sx={styleButtonSubmit}>
        Search
      </Button>
      <Filter categories={categories} onChangeType={onChangeType} currentType={type} showFilters={showFilters} />
    </Paper>
  );
});

export default Search;
