import {Button as MuiButton, styled} from '@mui/material';
import {PaletteColor, PaletteGradients} from '@mui/material/styles/createPalette';

import {linearGradient, pxToRem} from '@/utilities';

import {ButtonColorsTypesDefault, ButtonProps, ButtonSizesTypesDefault, ButtonVariantsTypesDefault} from './Button.types';

// Remove any boolean props that are used as flags to apply custom styling and opt not to write them to the DOM element
const filterProps = (prop: any) => prop !== 'circular' && prop !== 'icononly';

export default styled(MuiButton, {shouldForwardProp: prop => filterProps(prop)})<ButtonProps>(({
  theme,
  color = ButtonColorsTypesDefault,
  variant = ButtonVariantsTypesDefault,
  size = ButtonSizesTypesDefault,
  circular = false,
  icononly = false,
}) => {
  const {palette} = theme;
  const paletteColor = (palette[color as keyof typeof palette] || {main: '#e0e0e0', contrastText: 'rgb(123, 128, 154)'}) as PaletteColor;

  const gradientStyles = () => {
    const paletteGradient = palette['gradients' as keyof typeof palette] as PaletteGradients;
    const paletteGradientColor = paletteGradient[color as keyof typeof paletteGradient] as PaletteColor;
    const backgroundValue = linearGradient([paletteGradientColor.main, paletteGradientColor.dark]);

    return {
      background: backgroundValue,
      color: paletteColor.contrastText,

      '&:disabled': {
        background: backgroundValue,
        color: paletteColor.contrastText,
      },
    };
  };

  const circularStyles = () => ({
    borderRadius: pxToRem(160),
  });

  const icononlyStyles = () => {
    let sizeValue = '0';
    let paddingValue = '0';

    switch (size) {
      case 'small':
        sizeValue = pxToRem(25.4);
        paddingValue = pxToRem(4.5);
        break;

      case 'large':
        sizeValue = pxToRem(52);
        paddingValue = pxToRem(16);
        break;

      default:
        sizeValue = pxToRem(38);
        paddingValue = `${pxToRem(11)} ${pxToRem(11)} ${pxToRem(10)}`;
        break;
    }

    return {
      width: sizeValue,
      minWidth: sizeValue,
      height: sizeValue,
      minHeight: sizeValue,
      padding: paddingValue,

      '& .material-icons': {
        marginTop: 0,
      },

      '&:hover, &:focus, &:active': {
        transform: 'none',
      },
    };
  };

  return {
    ...(variant === 'gradient' && gradientStyles()),
    ...(circular && circularStyles()),
    ...(icononly && icononlyStyles()),
  };
});
