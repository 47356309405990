import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import chunk from 'lodash/chunk';

import {PropertyGalleryInterface} from '@/interfaces/properties';
import ImageRatio from '@/components/Image/ImageRatio';

export interface PropertyGalleryImagesProps {
  properties?: object;
  columns?: number;
  splitIntoPages?: boolean;
  aspectRatio?: string;
}

const styleImage = {
  borderRadius: '20px',
};

const stylePropertyWrapper = {
  margin: 0,
  padding: 0,
};

const PropertyGalleryImages = (property: PropertyGalleryInterface, columns = 4, splitIntoPages = false, aspectRatio = '6 / 4'): Array<JSX.Element> => {
  const elementsProperties: Array<JSX.Element> = [];

  if (property.hero)
    elementsProperties.push(
      <Grid key={`PropertyGalleryImages-${property.property_id}-0`} xs={12} md={12 / Math.min(columns, 4)}>
        <ImageRatio image={property.hero} title={property.name} aspectRatio={aspectRatio} sx={styleImage} />
      </Grid>
    );

  if (property.image)
    elementsProperties.push(
      <Grid key={`PropertyGalleryImages-${property.property_id}-0`} xs={12} md={12 / Math.min(columns, 4)}>
        <ImageRatio image={property.image} title={property.name} aspectRatio={aspectRatio} sx={styleImage} />
      </Grid>
    );

  for (let i = 2; i <= 10; i += 1) {
    // @ts-ignore
    const image = property[`SuperContainer_Link_${i}_RawData`];
    // @ts-ignore
    const hasImage = property[`Image_Available_${i}`] === 'Yes';

    if (hasImage && image) {
      elementsProperties.push(
        <Grid key={`PropertyGalleryImages-${property.property_id}-${i}`} xs={12} md={12 / Math.min(columns, 4)}>
          <ImageRatio image={image} title={`${property.name} - #${i}`} aspectRatio={aspectRatio} sx={styleImage} />
        </Grid>
      );
    }
  }

  if (splitIntoPages)
    return chunk(elementsProperties, columns).map((page, pageIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={`PropertyGalleryImages-${property.property_id}-${pageIndex}`} container spacing={3} sx={stylePropertyWrapper}>
        {page}
      </Grid>
    ));

  return [
    <Grid key="PropertyGalleryImages-0" container spacing={3} sx={stylePropertyWrapper}>
      {elementsProperties}
    </Grid>,
  ];
};

export default PropertyGalleryImages;
