/* eslint-disable react/prop-types */
import React from 'react';

import {Paper, ThemeOptions} from '@mui/material';

const styles = {
  display: 'grid',
  rowGap: '16px',
  position: 'absolute',
  top: 'calc(100% - 16px)',
  left: 0,
  borderRadius: '25px',
  width: '380px',
  // @ts-ignore
  px: (theme: ThemeOptions) => theme.spacing(3),
  // @ts-ignore
  py: (theme: ThemeOptions) => theme.spacing(4),
  listStyleType: 'none',
  zIndex: 10,

  li: {
    position: 'relative',
    cursor: 'pointer',
    zIndex: 1,
    fontWeight: 600,
    paddingLeft: '16px',
    '&:hover, &[aria-selected="true"]': {
      color: '#ffffff',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '-12px',
        width: '100%',
        height: 'calc(100% + 24px)',
        // @ts-ignore
        background: (theme: ThemeOptions) => theme.palette.background.highlight,
        zIndex: -1,
        borderRadius: '25px',
      },
    },
  },
};

// @ts-ignore
const Filter = ({categories, onChangeType, currentType, showFilters}) => {
  const type = (url: string) => {
    if (url === 'auckland-properties-for-sale/all') {
      return 'auckland-properties-for-sale';
    }
    return url.substring(url.lastIndexOf('/') + 1);
  };

  if (!categories?.find((category: any) => category.name === 'All')) {
    categories?.unshift({id: 0, name: 'All', url: '/all'});
  }

  return (
    // @ts-ignore
    <Paper sx={styles} elevation={6} component="ul" role="listbox" style={{display: showFilters ? 'grid' : 'none'}}>
      {categories?.map((category: any) => (
        <li
          role="option"
          aria-selected={currentType === type(category.url)}
          onClick={onChangeType}
          onKeyDown={onChangeType}
          key={category.id}
          data-value={type(category.url)}>
          {category.name}
        </li>
      ))}
    </Paper>
  );
};

export default Filter;
