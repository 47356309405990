import React from 'react';

import {ThemeOptions, useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import ImageRatio from '@/components/Image/ImageRatio';

const styleHeroBanner = (theme: ThemeOptions) => ({
  minHeight: `400px`,
  overflow: 'hidden',
  position: 'relative',
  padding: 0,
  borderRadius: '0 0 50px 50px',

  // @ts-ignore
  [theme.breakpoints.down('md')]: {
    borderRadius: '0 0 20px 20px',
  },

  '@media print': {
    display: 'none',
  },
});

export interface BannerSmallProps {
  image: string;
  title: string;
}

const BannerSmall = ({image, title}: BannerSmallProps) => {
  const theme = useTheme();

  return (
    <Grid container sx={styleHeroBanner(theme)} color="white" alignItems="end" justifyContent="center">
      <ImageRatio image={image} title={title} aspectRatio="5 / 1" background />
    </Grid>
  );
};

export default BannerSmall;
