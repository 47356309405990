import React from 'react';

import {Box} from '@mui/material';

import ImageRatio from '@/components/Image/ImageRatio';
import VideoRatio from '@/components/Video/VideoRatio';

const MAX_WIDTH_DEFAULT = Number(process.env.NEXT_PUBLIC_FRONTEND_MAX_WIDTH);

// Styles
const styleHeroBanner = {
  minHeight: '90vh',
  position: 'relative',
};

const styleContentHeroBanner = {
  marginBottom: 10,
  display: 'flex',
  flexDirection: {xs: 'column', lg: 'row'},
  justifyContent: {xs: 'flex-end', lg: 'space-between'},
  alignItems: {xs: 'start', lg: 'end'},
};

export interface BannerLargeProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  imageUrl?: string;
  videoUrl?: string | false;
}

const BannerLarge = ({title, description, imageUrl = '/images/home@2x.png', videoUrl}: BannerLargeProps) => (
  <Box sx={styleHeroBanner} color="white" display="flex">
    {!videoUrl && <ImageRatio image={imageUrl} title="banner background" aspectRatio="2 / 1" background overlay priority />}
    {videoUrl && <VideoRatio video={videoUrl} title="banner background" aspectRatio="2 / 1" background overlay autoPlay loop muted />}
    <Box sx={styleContentHeroBanner} maxWidth={`${MAX_WIDTH_DEFAULT}px`} mx="auto" px={3}>
      <Box mb={{xs: 3, lg: 0}}>{title}</Box>
      <Box maxWidth={{xs: '100%', lg: '33%'}}>{description}</Box>
    </Box>
  </Box>
);

export default BannerLarge;
