import React from 'react';

import {Modal as MuiModal} from '@mui/material';
import Box from '@mui/material/Box';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  minWdth: 500,
  borderRadius: '20px',
};

export interface ModalInterface {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
}

const Modal = ({open, onClose, children}: ModalInterface): JSX.Element => (
  <MuiModal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={styleModal}>{children}</Box>
  </MuiModal>
);

export default Modal;
