import {ButtonProps as MuiButtonProps} from '@mui/material';

export type ButtonVariantsTypes = 'text' | 'contained' | 'outlined' | 'gradient';
export const ButtonVariantsTypesDefault = 'contained';

// export type ButtonSizesTypes = 'small' | 'medium' | 'large';
export const ButtonSizesTypesDefault = 'medium';

export type ButtonColorsTypes = 'primary' | 'secondary' | 'dark' | 'success' | 'warning' | 'error' | 'light' | 'white';
export const ButtonColorsTypesDefault = 'primary';

export type Ref = HTMLButtonElement;

export interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'variant'> {
  /**
   * Defines the overall look of the button.
   */
  color?: ButtonColorsTypes;
  variant?: ButtonVariantsTypes;
  circular?: boolean;
  icononly?: boolean;
}
